import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import PetOfTheMonth from '../components/petOfTheMonth';

const PetsOfTheMonth = ({ data }) => {
  var pageTitle = 'Pets of the Month';

  const featuredPets = data?.allFeaturedPetsJson.nodes;

  return (
    <Layout slug="pets-of-the-month" title={pageTitle}>
      <h1>{pageTitle}</h1>
      {featuredPets.map(
        (
          {
            month,
            year,
            name,
            birth,
            fact,
            breed,
            toy,
            treat,
            activity,
            image,
          },
          index
        ) => (
          <PetOfTheMonth
            key={index}
            year={year}
            month={month}
            petName={name}
            petBirth={birth}
            petFact={fact}
            petBreed={breed}
            petToy={toy}
            s
            petActivity={activity}
            petImage={image}
          />
        )
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allFeaturedPetsJson(sort: { fields: [year, month], order: [DESC, DESC] }) {
      nodes {
        month
        year
        name
        birth
        breed
        activity
        fact
        toy
        treat
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default PetsOfTheMonth;
