import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import moment from "moment";

const PetOfTheMonth = ({
  year,
  month,
  petName,
  petBirth,
  petFact,
  petBreed,
  petToy,
  petTreat,
  petActivity,
  petImage,
}) => {
  const data = useStaticQuery(graphql`
    query {
      zigZagBackground: file(relativePath: { eq: "zig_zag_blue.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
    }
  `);

  return (
    <div className="border-2 border-vip-purple rounded-xl mb-4 overflow-hidden">
      <div className="bg-vip-pink text-center text-vip-purple p-4">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-normal ">
          Pet of the Month
        </h2>
        <span className="text-5xl font-bold">
          {year &&
          moment(year, "YYYY").format("YYYY") !== moment().format("YYYY")
            ? moment(`${month} ${year}`, "M YYYY").format("MMMM YYYY")
            : moment(month, "M").format("MMMM")}
        </span>
      </div>

      <div
        className="flex flex-col lg:flex-row bg-blue-200"
        style={{
          backgroundImage: `url(${getSrc(data.zigZagBackground)})`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "100%",
        }}
      >
        <div className="lg:w-1/2 p-8 lg:self-center">
          {petImage && (
            <GatsbyImage
              image={getImage(petImage)}
              alt={`Image of ${petName}`}
              className="block mx-auto mb-4 rounded-xl"
              style={{ maxWidth: "350px" }}
            />
          )}
          <span className="block text-4xl text-center">{petName}</span>
        </div>

        <div className="bg-white text-xl md:text-2xl lg:w-1/2 p-8">
          <h3 className="text-2xl md:text-3xl font-normal text-center">
            A Little Bit About ME!
          </h3>
          {petBirth && (
            <span className="block mb-3">
              <span className="font-bold">My Birthday or Gotcha Day:</span>{" "}
              {petBirth}
            </span>
          )}
          {petFact && (
            <span className="block mb-3">
              <span className="font-bold">Fun Fact About Me:</span> {petFact}
            </span>
          )}
          {petBreed && (
            <span className="block mb-3">
              <span className="font-bold">I am a:</span> {petBreed}
            </span>
          )}
          {petToy && (
            <span className="block mb-3">
              <span className="font-bold">My Favourite Toy:</span> {petToy}
            </span>
          )}
          {petTreat && (
            <span className="block mb-3">
              <span className="font-bold">My Favourite Treat:</span> {petTreat}
            </span>
          )}
          {petActivity && (
            <span className="block">
              <span className="font-bold">My Favourite Activity:</span>{" "}
              {petActivity}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PetOfTheMonth;
